import getters from './getters';

jest.mock('@vivotek/lib-medama', () => ({
  PLUGINFREE: { SUPPORT_VIDEO_CODEC: [] },
}));

describe('getters', () => {
  describe('activeViewIndex', () => {
    it('should return -1 if no active encoder', () => {
      const state = {
        activeEncoderId: -1,
      };
      expect(getters.activeViewIndex(state)).toEqual(-1);
    });

    it('should return correct view index', () => {
      const v = {
        name: 'test',
      };
      const state = {
        activeEncoderId: 10,
        view: [v],
      };
      const theGetters = {
        activeView: v,
      };
      expect(getters.activeViewIndex(state, theGetters)).toEqual(0);
    });
  });
  describe('activeView', () => {
    it('should return defaultViewCell when no encoders selected', () => {
      const state = {
        selectedEncoders: [],
        view: [],
      };
      expect(getters.activeView(state).channel).toEqual(-1);
    });
    it('should return encoder data with activeEncoderId', () => {
      const v = {
        channel: 0,
      };
      const state = {
        activeEncoderId: 0,
        view: [v],
      };
      expect(getters.activeView(state)).toEqual(v);
    });
  });
  describe('filteredView', () => {
    it('should return excluded view', () => {
      const view = [{ channel: 1 }, { channel: 2 }];
      const state = { view };
      expect(getters.filteredView(state)({ channel: 1, excluded: true })).toEqual([{
        channel: 2
      }]);
    });
    it('should return specific channel view', () => {
      const view = [{ channel: 1 }, { channel: 2 }];
      const state = { view };
      expect(getters.filteredView(state)({ channel: 1 })).toEqual([{
        channel: 1
      }]);
    });
    it('should return all channel view', () => {
      const view = [{ channel: 1 }, { channel: 2 }];
      const state = { view };
      expect(getters.filteredView(state)({})).toEqual([{ channel: 1 }, { channel: 2 }]);
    });
  });
});
